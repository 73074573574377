body {
  margin: 0;
  font-family: "Hellix", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 10px;
}
@media screen and (max-width: 1900px) {
  html {
    font-size: 8.5px;
  }
}
@media screen and (max-width: 1199px) {
  html {
    font-size: 7px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.desktopOnly {
  display: block;
}

.mobileOnly {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .desktopOnly {
    display: none !important;
  }

  .mobileOnly {
    display: block !important;
  }
}
